<template>
  <div>
    <el-row class="dash-board">
      <el-col :span="7">
        <p class="in1">培训班名称：</p>
        <el-input style="width: 200px" class="e-inps" v-model="className"></el-input>
      </el-col>
      <el-col :span="3" style="display: flex;align-items: center;">
        <el-button type="primary" @click="getOne" class="inquire">查询</el-button>
        <el-button type="warning" @click="reset" class="reset">重置</el-button>
      </el-col>
    </el-row>
    <el-row class="bg">
      <el-table
        :data="tableData"
        style="width: 100%;">
        <el-table-column
          label="培训班名称"
          align="center"
          width="300">
          <template slot-scope="scope">
            <span>{{ scope.row.className }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="班级人数"
          align="center"
          width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.classNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="班主任"
          align="center"
          width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.principalName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="作业数量"
          align="center"
          width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.submitWorkNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="办班地点"
          align="center"
          width="300">
          <template slot-scope="scope">
            <span>{{ scope.row.trainLocation }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center">
          <template slot-scope="scope">
            <!-- <el-button size="mini" type="success" v-if="scope.row.status== 0" @click="assessment(scope.row)" class="reset">填写作业</el-button> -->
            <el-button type="primary" @click="seeBt(scope.row)">查 看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top:20px">
     <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        :total="total"
        class="pag"
      >
    </el-pagination>
    </el-row>
    <el-dialog
      title="填写作业内容"
      :visible.sync="centerDialogVisible"
      width="600"
      center>
      <el-form :model="form" ref="form">
        <el-form-item label="作业方式：">
          <el-radio-group v-model="radioTwo">
            <el-radio :label="1">上传附件</el-radio>
            <el-radio :label="2">文本输入</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="radioTwo==2">
          <el-row>
            <el-form-item label="作业内容：">
              <el-input v-model="stuWord" type="textarea" rows="5"  style="width: 85%"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <span class="dialog-footer">
            <el-button @click="centerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="noAuth('form')">提交作业</el-button>
          </span>
          </el-row>
        </div>
        <div v-if="radioTwo==1">
          <el-row>
            <el-form-item label="附件：">
              <div class="el-form-item__content">
                <el-upload ref="upload"
                           accept=".xls,.xlsx,.pdf,.doc,.docx,.pptx,.ppt"
                           action="#"
                           :auto-upload="false"
                           :multiple="false"
                           :file-list="fileList"
                           :before-upload="beforeUpload"
                           :http-request="uploadHttpRequest"
                           :on-remove="fileRemove"
                           :on-change="fileChange">
                  <el-button size="small" type="primary">选择文件</el-button>
                  <!-- <el-button type="primary" size="small" @click="submitUpload">上 传</el-button> -->
                  <div slot="tip" class="el-upload__tip">只能上传.xls,.xlsx,.pdf,.doc,.docx,.pptx,.ppt文件，且不超过200M</div>
                </el-upload>
              </div>
            </el-form-item>
          </el-row>
          <el-row><el-button type="primary" size="small" @click="submitUpload">点击上传</el-button>
          </el-row>
          <el-row>
            <el-form-item style="text-align:center">
              <el-button @click="centerDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="uploadBt('form')">提交作业</el-button>
            </el-form-item>
          </el-row>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      title="作业详情"
      :data="details"
      :visible.sync="centerDialogVisibleTwo"
      width="700"
      center>
      <el-row type="flex" class="row-bg">
        <el-col :span="2.5"><div>提交的内容：</div></el-col>
        <el-col :span="20.5">
          <div class="row-text">{{details.teachSuggest}}
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisibleTwo = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { upload, updWordUrl, details, findStuClass } from '@/api/training.js'
  export default {
    name: 'task',
    data () {
      return {
        stuWorkId: '',
        radio: 1,
        radioTwo: 1,
        id: '',
        className: '',
        status: '',
        statelist: [
          { id: 1, value: '待评估' },
          { id: 2, value: '已评估' }
        ],
        tableData: [],
        datalist: [],
        // tableData: {
        //   id: '',
        //   className: '',
        //   status: ''
        // },
        fileList: [],
        details: {},
        remark: '',
        centerDialogVisible: false,
        centerDialogVisibleTwo: false,
        form: {
          id: ''
        },
        teachResultList: [
          { id: 1, value: '优' },
          { id: 2, value: '良' },
          { id: 3, value: '中' },
          { id: 4, value: '差' }
        ],
        total: 0,
        pageNum: 1,
        pageSize: 10,
        list: '',
        stuWord: ''
      }
    },
    mounted () {
      // 钩子函数
      this.getOne()
    },
    watch: {
      dialogVisible: function(currentValue) {
        this.showMapComponent = currentValue
        if (currentValue) {
          this.keyword = ''
        }
      }
    },
    methods: {
      seeBt(row) {
        this.$router.push({ path: '/taskAdd', query: { id: row.classId, className: row.className } })
      },
      // 上传文件之前的钩子：判断上传文件格式、大小等，若返回false则停止上传
      beforeUpload(file) {
        // 文件类型
        // const isType = file.type === 'application/vnd.ms-excel'
        // const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        // const fileType = isType || isTypeComputer
        var fileType = file.name.substring(file.name.lastIndexOf('.') + 1)
        // 判断文件格式
        if (!(fileType === 'xls' || fileType === 'xlsx' || fileType === 'doc' || fileType === 'docx' || fileType === 'pdf' || fileType === 'pptx' || fileType === 'ppt')) {
          this.$message.error('上传文件只能是xls/xlsx/pdf/doc/docx/pptx/ppt格式！')
        }
        // 文件大小限制为10M
        const fileLimit = file.size / 1024 / 1024 < 200
        if (!fileLimit) {
          this.$message.error('上传文件大小不超过200M！')
        }
        return fileType && fileLimit
      },
      // 自定义上传方法，param是默认参数，可以取得file文件信息，具体信息如下图
      uploadHttpRequest(param) {
        const formData = new FormData() // FormData对象，添加参数只能通过append('key', value)的形式添加
        formData.append('file', param.file) // 添加文件对象 要提交给后台的文件
        // formData.append('uploadType', this.rulesType)
        upload(formData).then(resp => {
          console.log(resp)
          if (resp.data.code === '200') {
            this.$notify({
              title: '成功',
              message: '上传成功',
              type: 'success'
            })
            this.list = resp.data.data
          }
        }).catch(err => {
          console.log('失败', err)
          param.onError() // 上传失败的文件会从文件列表中删除
        })
      },
      // 点击上传：手动上传到服务器，此时会触发组件的http-request
      submitUpload() {
        // if (!this.fileLists || !this.fileLists.length) {
        //   this.$notify.error({
        //     title: '错误',
        //     message: '请选择上传的文件',
        //     duration: 2000
        //   })
        this.$refs.upload.submit()
      },
      // 文件发生改变
      fileChange(file, fileList) {
        if (fileList.length > 0) {
          this.fileList = [fileList[fileList.length - 1]] // 展示最后一次选择的文件
        }
      },
      // 移除选择的文件
      fileRemove(file, fileList) {
        if (fileList.length < 1) {
          this.uploadDisabled = true // 未选择文件则禁用上传按钮
          this.$emit('submitUpload:visible', false) // 直接修改父组件的属性
        }
      },
      getOne () {
        const query = {
          className: this.className,
          workTime: this.workTime,
          userName: this.$store.getters.username,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        findStuClass(query).then(resp => {
          if (resp.data.code === '200') {
            this.tableData = resp.data.data.rows
            this.total = resp.data.data.total
          }
          console.log(this.tableData)
        })
      },
      reset() {
        this.className = ''
        this.status = ''
      },
      assessment (row) {
        this.centerDialogVisible = true
        this.stuId = row.stuId
        this.stuWorkId = row.stuWorkId
      },
      uploadBt() { // 附件作业提交
       if (this.list.url !== '') {
        const query = {
          stuWorkId: this.stuWorkId,
          url: this.list.url
        }
        updWordUrl(query).then(resp => {
          if (resp.data.code === '200') {
            this.centerDialogVisible = false
            this.$notify({
              title: '作业提交成功',
              type: 'success'
            })
            this.getOne()
          }
        })
       } else {
         this.$notify.info({
              message: '请先点上传完文件再提交作业'
          })
       }
      },
      noAuth () {
        if (this.stuWord !== '') {
          const query = {
            stuWorkId: this.stuWorkId,
            stuWord: this.stuWord
          }
          updWordUrl(query).then(resp => {
            if (resp.data.code === '200') {
              this.centerDialogVisible = false
              this.$notify({
                title: '作业提交成功',
                type: 'success'
              })
              this.getOne()
            }
          })
        } else {
         this.$notify.info({
              message: '作业内容不能为空'
          })
       }
      },
      see (id) {
        this.centerDialogVisibleTwo = true
        this.id = id
        details(this.id).then(resp => {
          this.details = resp.data.data
        })
      },
      handleSizeChange(e) {
        this.pageSize = e
        this.getOne()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.getOne()
      }
    }
  }
</script>

<style scoped>
  .dash-board {display: flex; background-color: white; height: 60px; padding: 10px 0;}
  .bg{margin-top: 15px;  padding: 20px; background-color: white;}
  .in1{font-size: 13px; position: absolute; top: 10px; left: 17px}
  .in2{font-size: 13px; position: absolute; top:10px; left: 330px}
  .in3{width: 160px; position: absolute; top: 14px;left: 400px}
  .spani{color: #2c2fd6}
  .e-inps{position: absolute; left: 100px; top: 14px}
  .row-bg{ padding: 10px 0px;}
  .row-text{line-height: 24px;}
</style>
